// extracted by mini-css-extract-plugin
export var button = "external-project-page-module--button--7c778";
export var buttonIcon = "external-project-page-module--button-icon--456a1";
export var callToActionButtons = "external-project-page-module--call-to-action-buttons--b6d27";
export var callToActionButtonsContainer = "external-project-page-module--call-to-action-buttons-container--f4f64";
export var callToActionCategory = "external-project-page-module--call-to-action-category--5b16b";
export var callToActionCategorySpecification = "external-project-page-module--call-to-action-category-specification--5ef1d";
export var callToActionDescription = "external-project-page-module--call-to-action-description--f47a2";
export var callToActionSupport = "external-project-page-module--call-to-action-support--b7a26";
export var licenseFootnote = "external-project-page-module--license-footnote--187d4";
export var pageHeadingCTA = "external-project-page-module--page-heading-CTA--e3a23";
export var primaryContentAside = "external-project-page-module--primary-content-aside--b0bf5";
export var primaryContentMain = "external-project-page-module--primary-content-main--7cf92";
export var projectPageIssue = "external-project-page-module--project-page-issue--ab5b3";
export var projectPageIssueFooter = "external-project-page-module--project-page-issue-footer--2d93e";
export var projectPageIssueFooterDiscussion = "external-project-page-module--project-page-issue-footer-discussion--1abbf";
export var projectPageIssueFooterDiscussionCount = "external-project-page-module--project-page-issue-footer-discussion-count--59214";
export var projectPageIssueFooterDiscussionIcon = "external-project-page-module--project-page-issue-footer-discussion-icon--b441e";
export var projectPageIssueFooterMeta = "external-project-page-module--project-page-issue-footer-meta--ea511";
export var projectPageIssueHeader = "external-project-page-module--project-page-issue-header--05325";
export var projectPageIssueHeaderIcon = "external-project-page-module--project-page-issue-header-icon--fd0f3";
export var projectPageIssueHeaderTitle = "external-project-page-module--project-page-issue-header-title--ca368";
export var projectPageLayout = "external-project-page-module--project-page-layout--98f58";
export var projectScreenshot = "external-project-page-module--project-screenshot--ac2d3";
export var projectScreenshotLink = "external-project-page-module--project-screenshot-link--aeda7";
export var projectScreenshotList = "external-project-page-module--project-screenshot-list--88c71";
export var projectScreenshotListItem = "external-project-page-module--project-screenshot-list-item--df2df";
export var projectScreenshotsContainer = "external-project-page-module--project-screenshots-container--f051f";
export var reactImages__viewImage = "external-project-page-module--react-images__view-image--01ce0";
export var repoStat = "external-project-page-module--repo-stat--0bec7";
export var repoStatCount = "external-project-page-module--repo-stat-count--3730c";
export var repoStatIcon = "external-project-page-module--repo-stat-icon--f773c";
export var repoStatLabel = "external-project-page-module--repo-stat-label--a8856";
export var repoStats = "external-project-page-module--repo-stats--90716";
export var sidebarTagListTag = "external-project-page-module--sidebar-tag-list-tag--75e81";
export var sidebarTags = "external-project-page-module--sidebar-tags--53453";
export var subProject = "external-project-page-module--sub-project--58ac6";
export var subProjectCallToAction = "external-project-page-module--sub-project-call-to-action--b0977";
export var subProjectCopy = "external-project-page-module--sub-project-copy--a4375";
export var subProjectDescription = "external-project-page-module--sub-project-description--89984";
export var subProjectList = "external-project-page-module--sub-project-list--c5b6b";
export var subProjectName = "external-project-page-module--sub-project-name--6bee7";
export var videoResponsive = "external-project-page-module--video-responsive--275a4";